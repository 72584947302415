<template>
  <div>
    <div class="d-flex justify-space-between mb-4">
      <v-btn :to="{name: 'clientSecond'}" text color="error" outlined>
        <v-icon>mdi-arrow-left</v-icon>
        <span> Back</span>
      </v-btn>
      <v-btn @click="openAddModals()" text outlined color="indigo accent-4">
        Add branch
      </v-btn>
    </div>

    <v-card class="pa-3 mt-5">
      <v-card-title>Branches</v-card-title>
      <v-card class="ma-2 pa-4">
        <!-- <v-table> -->
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="dataItem"
          >

            <template v-slot:item.actions="{item}">
              <div class="d-flex justify-space-between">
<!--                <v-icon @click="reRegisterClientEpos(item.id)" class="mx-2">mdi-update</v-icon>-->
                <v-icon @click="toggleActivation(item.id)" class="cursor-pointer mr-4 pa-0" style="color: #ff9800">
                  {{ item.isDeleted ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}
                </v-icon>
<!--                <v-btn @click="openPassword(item.id)" icon color="green" small>-->
<!--                  <v-icon small> mdi-lock</v-icon>-->
<!--                </v-btn>-->
                <v-btn @click="getclientBranch(item.id)" icon color="#397dfb" small>
                  <v-icon small> mdi-pencil</v-icon>
                </v-btn>
<!--                <v-btn icon color="warning" small @click="getclientBranch(item.id)">-->
<!--                  <v-icon small> mdi-eye</v-icon>-->
<!--                </v-btn>-->
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <!-- </v-table> -->
      </v-card>

    </v-card>
    <!-- modals-- -->
    <v-dialog width="500" v-model="openmodal">
      <v-card>
        <v-card-title v-if="update">Add Client Branch</v-card-title>
        <v-card-title v-else>edit client branch</v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field v-model='branch.name' outlined label="Branch name"></v-text-field>
            <v-text-field v-model='branch.smsName' outlined label="SMS name"></v-text-field>
            <div>Uzcard</div>
            <v-text-field v-model="branch.svGate.merchantId" outlined label="Merchant ID"></v-text-field>
            <v-text-field v-model="branch.svGate.terminalId" outlined label="Terminal ID"></v-text-field>
            <v-text-field v-model="branch.svGate.name" outlined label="Name"></v-text-field>
            <div>Humo</div>
            <v-text-field v-model="branch.humo.merchantId" outlined label="Merchant ID"></v-text-field>
            <v-text-field v-model="branch.humo.terminalId" outlined label="Terminal ID"></v-text-field>
            <v-text-field v-model="branch.humo.name" outlined label="Name"></v-text-field>
            <v-row>
              <v-col>
                <v-btn style="width: 100%" color="error" @click="openmodal = false">cancel</v-btn>
              </v-col>
              <v-col>
                <v-btn v-if="update" @click="addnewBranch()" style="width: 100%" color="success">create</v-btn>
                <v-btn v-else color="" @click="editBranch(branch.id)" style="width: 100%">edit</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- modals -->
  </div>
</template>

<script>
export default {
  name: 'ClientsView',
  beforeRouteEnter(to, from, next) {
    if ('id' in to.params) {
      next()
    } else {
      next({name: 'ClientSecond'})
    }
  },
  mounted() {
    this.Id = this.$route.params.id
  },
  data() {
    return {
      headers: [
        {text: 'ID', value: 'id', align: 'center', sortable: false},
        {text: 'Name', value: 'name', align: 'center', sortable: false},
        {text: 'SMS Name', value: 'smsName', align: 'center', sortable: false},
        {text: 'isDeleted', value: 'isDeleted', align: 'center', sortable: false},
        {text: 'actions', value: 'actions', align: 'center', sortable: false}
      ],
      openmodal: false,
      update: true,
      viewbranches: [],
      Group: [],
      branch: {
        clientId: '',
        id: null,
        name: '',
        smsName: '',
        svGate: {
          merchantId: '',
          terminalId: '',
          name: ''
        },
        humo: {
          merchantId: '',
          terminalId: '',
          name: ''
        },
      },
      dataItem: []
    }
  },
  methods: {
    openAddModals() {
      this.emptyBranch()
      this.openmodal = true
      this.update = true
    },
    emptyBranch() {
      this.branch.clientId = this.$route.params.id,
          this.branch.name = '',
          this.branch.smsName = '',
          this.branch.svGate.merchantId = '',
          this.branch.svGate.terminalId = '',
          this.branch.svGate.name = '',
          this.branch.humo.merchantId = '',
          this.branch.humo.terminalId = '',
          this.branch.humo.name = ''

    },
    addnewBranch() {
      this.createBranch()
      this.openmodal = false
    },
    async createBranch() {
      try {
        const res = await this.$http.post(`Client/addClientBranch`, this.branch);
        if (!res.error) {
          console.log(res.result);
          this.successNotification();
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async editBranch(id) {
      try {
        const res = await this.$http.post(`Client/editClientBranch`, this.branch);
        if (!res.error) {
          console.log(res.result);
          this.successNotification();
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async getclientBranch(id, branchId) {
      this.openmodal = true
      this.update = false
      try {
        const res = await this.$http.get(`Client/getClientBranch?clientId=${this.$route.params.id}&branchId=${id}`).then((res) => {
          if (!res.error) {
            console.log(res.result);
            this.branch = {...res.result}
            this.branch.smsName = res.result.smsName ?? '';
            // this.successNotification();
          }
        });
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },

    async toggleActivation(id) {
      try {
        const res = await this.$http.get(`Client/toggleClientBranchActivation?clientId=${this.$route.params.id}&branchId=${id}`).then((res) => {
          if (!res.error) {
            // console.log(res.result);
            this.branch = res.result;
          }
        })
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },

    async getAccess() {
      await this.$http.get(`Client/getAccesses`).then((res) => {
        // console.log(res.result.groups)
        this.Group = res.result.groups
      })
    }
  },


  created() {
    let id = this.$route.params.id
    this.$http.get(`Client/getClientBranches?clientId=${id}`).then((res) => {
      if (res && res.result) {
        // console.log(res.result.branches);
        this.dataItem = res.result.items
      } else {
        this.$router.push({name: 'ClientSecond'})
      }
    }),
        this.getAccess()


    // this.getclientBranch()
  },
}
</script>